class NewsCardIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('NewsCard before load');
	}

	afterLoad() {
		// console.log('NewsCard After load');
		this.$el = $(this);
	}

	beforeUpdate() {
		// console.log('NewsCard before update');
	}

	afterUpdate() {
		// console.log('NewsCard after update');
	}

	unload() {
		// console.log('NewsCard after unload');
	}
}

!customElements.get('bat-card-gloitnews') &&
	customElements.define('bat-card-gloitnews', NewsCardIT);
